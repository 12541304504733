import { render, staticRenderFns } from "./InfoCurso.vue?vue&type=template&id=218d2d54&"
import script from "./InfoCurso.vue?vue&type=script&lang=js&"
export * from "./InfoCurso.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VCardText,VChip,VFlex,VIcon,VImg,VLayout,VProgressCircular,VSubheader})

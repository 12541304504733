<template>
<v-card-text class="pt-0">
    <v-layout row wrap>
    <v-flex lg12 xs12 >
        <v-subheader class="font-weight-black secondary--text">Informacion General del Curso</v-subheader>
    </v-flex>
    <v-flex lg4 md4 xs12 v-if="!$vuetify.breakpoint.xs" >
        <v-img
            :src="`${$docUrl}/${curso.foto}`"
            class="grey lighten-2"
            contain
            aspect-ratio="1.5"
        >
            <template v-slot:placeholder>
                <v-layout
                fill-height
                align-center
                justify-center
                ma-0
                >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-layout>
            </template>
        </v-img>
    </v-flex>
    <v-flex lg8 md8 xs12>
        <v-layout row wrap>
            <v-flex lg4 xs5 class="text-right font-weight-bold grey--text pr-2">Titulo:</v-flex>
            <v-flex lg8 xs7 class="py-1">{{curso.titulo}}</v-flex>
            <v-flex lg4 xs5 class="text-right font-weight-bold grey--text pr-2">Tema:</v-flex>
            <v-flex lg8 xs7 class="py-1 font-weight-bold">{{curso.tema}}</v-flex>
            <v-flex lg4 xs5 class="text-right font-weight-bold grey--text pr-2">Tipo:</v-flex>
            <v-flex lg8 xs7 > <v-chip small color="secondary" class="ma-0" dark>{{curso.tipo}}</v-chip> </v-flex>
            <v-flex lg4 xs5 class="text-right font-weight-bold grey--text pr-2">Fecha Evento:</v-flex>
            <v-flex lg8 xs7 class="py-1"><v-icon>mdi-calendar</v-icon> {{formatDateText(curso.fechaEvento)}}</v-flex>
            <v-flex lg4 xs5 class="text-right font-weight-bold grey--text pr-2">Horario:</v-flex>
            <v-flex lg8 xs7 class="py-1">{{curso.horaInicio}} A {{curso.horaFin}}</v-flex>
            <v-flex lg4 xs5 class="text-right font-weight-bold grey--text pr-2">Lugar Evento:</v-flex>
            <v-flex lg8 xs7 class="py-1">
                {{curso.lugarEvento}}
            </v-flex>
        </v-layout>
    </v-flex>
    </v-layout>
</v-card-text>
</template>
<script>
import mixinDateTime from "@/mixins/datetime"
export default {
    props:{
        curso:Object
    },
    mixins: [mixinDateTime],
    data(){
        return{
            loading:true
        }
    }
}
</script>